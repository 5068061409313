<template>
    <b-row class="justify-content-center">
        <b-col md="12" lg="12" xl="10">
        <CCard>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(submit)">
                    <CCardHeader class="d-flex justify-content-between">
                        <h4 class="m-0">{{ $t('pages.vdi.rdsh.headers.edit') }}</h4>
                        <b-button type="submit" size="sm" variant="orange">{{ $t('buttons.save') }}</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                            <b-row>
                                <b-col xl="6">
                                    <validation-provider
                                        :name="$t('pages.vdi.rdsh.form.name', { language: $t('generally.languages.de') }).toString()"
                                        :rules="{ required: true, min: 3, max: 32 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                            :label="$t('pages.vdi.rdsh.form.name', { language: $t('generally.languages.de') })"
                                        >
                                            <b-form-input
                                                type="text"
                                                name="name-de"
                                                :state="getValidationState(validationContext)"
                                                v-model="form.name.de"
                                                aria-describedby="name-de-feedback"
                                            />
                                            <b-form-invalid-feedback id="name-de-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <validation-provider
                                        :name="$t('pages.vdi.rdsh.form.name', { language: $t('generally.languages.en') }).toString()"
                                        :rules="{ required: true, min: 3, max: 32 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                            :label="$t('pages.vdi.rdsh.form.name', { language: $t('generally.languages.en') })"
                                        >
                                            <b-form-input
                                                type="text"
                                                name="name-en"
                                                :state="getValidationState(validationContext)"
                                                v-model="form.name.en"
                                                aria-describedby="name-en-feedback"
                                            />
                                            <b-form-invalid-feedback id="name-en-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <validation-provider
                                        :name="$t('pages.vdi.rdsh.form.description', { language: $t('generally.languages.de') }).toString()"
                                        :rules="{ required: true, min: 3, max: 512 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.rdsh.form.description', { language: $t('generally.languages.de') }) }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.rdsh.form.hints.description')"/>
                                            </template>
                                            <b-form-textarea
                                                name="description-de"
                                                :state="getValidationState(validationContext)"
                                                v-model="form.description.de"
                                                aria-describedby="description-de-feedback"
                                                rows="2"
                                                max-rows="5"
                                            ></b-form-textarea>
                                            <b-form-invalid-feedback id="description-de-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <validation-provider
                                        :name="$t('pages.vdi.rdsh.form.description', { language: $t('generally.languages.en') }).toString()"
                                        :rules="{ required: true, min: 3, max: 512 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.rdsh.form.description', { language: $t('generally.languages.en') }) }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.rdsh.form.hints.description')"/>
                                            </template>
                                            <b-form-textarea
                                                name="description-en"
                                                :state="getValidationState(validationContext)"
                                                v-model="form.description.en"
                                                aria-describedby="description-en-feedback"
                                                rows="2"
                                                max-rows="5"
                                            ></b-form-textarea>
                                            <b-form-invalid-feedback id="description-en-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <validation-provider
                                        :name="$t('pages.vdi.rdsh.form.image').toString()"
                                        :rules="{ required: false, max: 255 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.rdsh.form.image.label') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.rdsh.form.hints.image')"/>
                                            </template>
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <b-input-group-text class="bg-white">
                                                        <img :src="form.image" v-show="picture" width="20" height="20" @load="picture = true" @error="picture = false">
                                                        <b-icon-display v-show="!picture" class="text-orange"/>
                                                    </b-input-group-text>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    type="url"
                                                    name="image"
                                                    maxlength="255"
                                                    :state="getValidationState(validationContext)"
                                                    v-model="form.image"
                                                    :placeholder="$t('pages.vdi.rdsh.form.image.placeholder')"
                                                    aria-describedby="image-feedback"
                                                />
                                                <b-form-invalid-feedback id="image-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>
                                    </validation-provider>
                                    <b-form-group
                                        label-cols-sm="12"
                                        label-cols-md="6"
                                        label-cols-lg="5"
                                    >
                                        <template #label>
                                            {{ $t('pages.vdi.rdsh.form.disabled') }}
                                            <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.rdsh.form.hints.disabled')"/>
                                        </template>
                                        <b-form-select v-model="form.disabled">
                                            <b-form-select-option :value="false">{{ $t('pages.vdi.rdsh.values.disabled.enabled') }}</b-form-select-option>
                                            <b-form-select-option :value="true">{{ $t('pages.vdi.rdsh.values.disabled.disabled') }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group
                                        label-cols-sm="12"
                                        label-cols-md="6"
                                        label-cols-lg="5"
                                    >
                                        <template #label>
                                            {{ $t('pages.vdi.rdsh.form.public') }}
                                            <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.rdsh.form.hints.public')"/>
                                        </template>
                                        <b-form-select v-model="form.public">
                                            <b-form-select-option :value="false">{{ $t('pages.vdi.rdsh.values.public.no') }}</b-form-select-option>
                                            <b-form-select-option :value="true">{{ $t('pages.vdi.rdsh.values.public.yes') }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <validation-provider
                                        :name="$t('pages.vdi.rdsh.form.rdpTemplate').toString()"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.rdsh.form.rdpTemplate') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.rdsh.form.hints.rdpTemplate')"/>
                                            </template>
                                            <vue-codeditor style="font-size: 14px" v-model="form.rdpTemplate" mode="eiffel" theme="monokai"/>
                                            <b-form-invalid-feedback id="template-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col xl="6">
                                    <b-form-group>
                                        <div>
                                            {{ $t('pages.vdi.pools.settings.variables.defaultVariables.description') }}:<br>
                                            <ul>
                                                <li>{userName} = {{ $t('pages.vdi.pools.settings.variables.defaultVariables.username') }}</li>
                                            </ul>
                                        </div>
                                    </b-form-group>
                                    <b-form-group
                                        v-for="(val, key) in form.rdpTemplateVariables"
                                        :key="key"
                                        label-cols-sm="12"
                                        label-cols-md="7"
                                        label-size="sm"
                                        :label="val[getLocale()]"
                                    >
                                        <b-input-group size="sm">
                                            <b-form-input
                                                :name="val.name"
                                                size="sm"
                                                v-model="val.value"
                                            ></b-form-input>
                                            <b-input-group-append>
                                                <b-input-group-text>
                                                    {{ val.name }}
                                                </b-input-group-text>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                    <CLink href="https://docs.microsoft.com/de-de/windows-server/remote/remote-pool-services/clients/rdp-files" target="_blank">
                                        <b-icon-box-arrow-up-right/> {{ $t('pages.vdi.rdsh.form.externalDescription') }}
                                    </CLink>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-end">
                        <b-button type="submit" variant="orange">{{ $t('buttons.save') }}</b-button>
                    </CCardFooter>
                </b-form>
            </validation-observer>
        </CCard>
        </b-col>
    </b-row>
</template>

<script>
import rdshService from "../../../services/rdshService";

export default {
    name: "AppsEdit",
    props: ["id"],
    data: () => ({
        types: [],
        domainOptions: [],
        form: {
            name: {
                de: '',
                en: ''
            },
            description: {
                de: '',
                en: ''
            },
            template: '',
            disabled: false,
            public: false,
            image: '',
            templateVariables: {}
        },
        picture: false,
        loading: false,
    }),
    mounted() {
        this.load();
    },
    methods: {
        load(){
            rdshService.get(this.id).then(response => {
                response.data.name = JSON.parse(response.data.name)
                response.data.description = JSON.parse(response.data.description)
                //response.data.rdpTemplateVariables = JSON.parse(response.data.rdpTemplateVariables);
                this.form = response.data;
            }).catch()
        },
        getLocale(){
            return localStorage.getItem("locale");
        },
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                let data = Object.assign({}, this.form)
                data.name = JSON.stringify(data.name)
                data.description = JSON.stringify(data.description)
                //data.rdpTemplateVariables = JSON.stringify(data.rdpTemplateVariables)
                rdshService.edit(data).then(() => {
                    this.$toastr.s(this.$t('pages.vdi.rdsh.messages.success.edited'));
                    this.$router.push({name: 'VdiRDSH'})
                    this.loading = false;
                }).catch(error => {
                    this.errorHandler(error)
                    this.loading = false;
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
